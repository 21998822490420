<template>
  <el-container>
    <el-header>
      <Header></Header>
    </el-header>
    <el-row type="flex" justify="center" class="bg">
      <el-col :span="8">
        <el-card class="box-card">
          <template #header>
            <div class="card-header">
              <h2>{{$t('contactUs.header.title')}}</h2>
              <p>Contattaci via mail: <a href="mailto:s.globali.esd@gmail.com">s.globali.esd@gmail.com</a> </p>
            </div>
          </template>
        </el-card>
      </el-col>
    </el-row>
    <el-footer>
      <Footer></Footer>
    </el-footer>
  </el-container>

</template>

<script>
import Header from "../components/Header";
import Footer from "../components/Footer";

export default {
  name: "AboutUs",
  components: {Header, Footer},
}
</script>

<style scoped>

  .bg {
    height: 100vh;
    background: #ededed;
    width: 100%;
    align-items: center;
  }

</style>
